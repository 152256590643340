import React, { useState } from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { LineWrapper } from "../global/common/containers"

import { GalleryInner } from "../global/gallery/common"
import ClassicImage from "../global/gallery/ClassicImage"
import GreenImage from "../global/gallery/GreenImage"
import SingleDescription from "../global/gallery/SingleDescription"

import ImagesLightbox from "../global/gallery/ImagesLightbox"

const GalleryWrapper = styled(LineWrapper)`
  padding-top: var(--section-spacing);
  padding-bottom: calc(120px + var(--section-spacing));

  @media ${maxD.tablet} {
    padding-bottom: calc(var(--section-spacing) / 2);
  }
`

const Gallery = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isLightboxVisible, setLightboxVisibility] = useState(false)

  const title = data.careersGalleryTitle
  const gallery = data.careersGallery

  const handleImageClick = (event, i) => {
    event.preventDefault()

    setLightboxVisibility(true)
    setCurrentSlide(i)
  }

  return (
    <GalleryWrapper style={{ zIndex: "inherit" }}>
      <GalleryInner className="classic">
        {gallery &&
          gallery.map((galleryItem, i) => {
            const id = galleryItem.id

            const itemData = {
              url: galleryItem.localFile.childImageSharp.gatsbyImageData,
              fullUrl: galleryItem.localFile.publicURL,
              alt: galleryItem.altText,
            }

            const getGalleryElement = elementType => {
              switch (elementType) {
                case "singleDescriptionAndImage":
                  return (
                    <React.Fragment key={`small-${id}`}>
                      <SingleDescription title={title} />
                      <ClassicImage
                        onImageLinkClick={event => handleImageClick(event, i)}
                        {...itemData}
                      />
                    </React.Fragment>
                  )
                case "greenImage":
                  return (
                    <GreenImage
                      onImageLinkClick={event => handleImageClick(event, i)}
                      key={`small-${id}`}
                      {...itemData}
                    />
                  )
                default:
                  return (
                    <ClassicImage
                      onImageLinkClick={event => handleImageClick(event, i)}
                      key={`small-${id}`}
                      {...itemData}
                    />
                  )
              }
            }

            let elementType

            if (i === 7) {
              elementType = "singleDescriptionAndImage"
            } else if (i === 1 || i === 5 || i === 6 || i === 9) {
              elementType = "greenImage"
            } else {
              elementType = "classicImage"
            }

            return getGalleryElement(elementType)
          })}
      </GalleryInner>
      <ImagesLightbox
        slides={gallery}
        currentSlide={currentSlide}
        isLightboxVisible={isLightboxVisible}
        setLightboxVisibility={setLightboxVisibility}
      />
    </GalleryWrapper>
  );
}

export default Gallery
